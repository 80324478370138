:root{
    --orange:#ffa600;
}
.join{
    display: flex;
    padding: 0 2rem;
    gap: 10rem;
}
.left-j{
    color: white;
    position: relative;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 3rem;
}
.left-j>hr{
     position: absolute;
     width: 10.5rem;
     border: 2px solid #ffa600;
     border-radius: 20%;
     margin: -10px 0;
}
.rigth-j{
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.email-container{
    display: flex;
    gap: 3rem;
    background-color: grey;
    padding: 1rem 2rem;
}
.email-container>input{
    background-color: transparent;
    border: none;
    outline: none;
    color: lightgray;
}
::placeholder{
    color: lightgray;
}
.btn-j{
    background-color: orange;
    padding: 0.5rem;
    color: white;
    font-weight: bold;
    border: 4px solid transparent;
    transition: 300ms all;
    display: flex;
    align-items: center;
    justify-content: center;
}